import os from "os";

const hostname = os.hostname();

export const backend = (() => {
  if (
    hostname.indexOf("localhost") !== -1 ||
    hostname.indexOf("camorte.eu.ngrok.io") !== -1
  ) {
    return "http://localhost:8080";
  } else if (hostname.indexOf("test.iterate.no") !== -1) {
    return "https://arve-backend.test.iterate.no";
  } else if (hostname.indexOf("app.iterate.no") !== -1) {
    return "https://arve-backend.app.iterate.no";
  }
})();
