/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import axios from "axios";
import { backend } from "../environment";
import { validateEmail, validateInput } from "../utils/validateInput";
import InputField from "../components/InputField";
import ButtonBlack from "../components/buttons/ButtonBlack";

function Profile() {
  const [errText, setErrText] = useState<string[]>([]);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios(`${backend}/user/info`, {
      method: "GET",
      withCredentials: true,
    })
      .then((response) => {
        setLoggedIn(true);
        setName(response.data.name);
        setEmail(response.data.email);
        setAddress1(response.data.address1);
        setAddress2(response.data.address2);
        setPostNr(response.data.postNr + "");
        setPoststed(response.data.postSted);
        setPhonenumber(response.data.phoneNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const generateErrText = () => {
    let errText = [];
    if (!validateInput(name)) {
      errText.push("Bruker må ha et navn.");
    }
    if (!validateEmail(email)) {
      errText.push("Ugyldig epost.");
    }
    if (!validateInput(phonenumber)) {
      errText.push("Ugyldig tlf. nummer.");
    }
    if (!validateInput(address1)) {
      errText.push("Adresse 1 er ugyldig.");
    }
    if (!validateInput(address2)) {
      errText.push("Addresse 2 er ugyldig.");
    }
    if (!validateInput(postNr)) {
      errText.push("Postnr. er ugyldig.");
    }
    if (!validateInput(poststed)) {
      errText.push("Poststed er ugyldig.");
    }
    setErrText(errText);
    return errText;
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [poststed, setPoststed] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [postNr, setPostNr] = useState("");

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin: 5vw 10vw;
      `}
    >
      <div
        css={css`
          background: #fff281;
          display: flex;
          align-items: center;
          padding: 30px 28px;
          justify-content: center;
        `}
      >
        Hei, {name}
      </div>
      <h2
        css={css`
          color: red;
        `}
      >
        {errText.length > 0 && (
          <>
            <p>
              Følgende feil ble funnet: <br></br>{" "}
            </p>

            <ul>
              {errText.map((text) => {
                return <li>{text}</li>;
              })}
            </ul>
          </>
        )}
      </h2>
      <p>
        Informasjon som er lagret her er viktig for å kunne sende bestillinger
        og produkter til deg. I tillegg til å gjøre det mulig for å sende status
        på pakkene som blir sendt til deg.
      </p>
      <p>Din informasjon: </p>
      {loggedIn && (
        <div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <InputField
              validateInput={validateInput}
              fieldName={"Navn"}
              inputValue={name}
              setValue={setName}
            />
            <InputField
              validateInput={validateEmail}
              fieldName={"E-post"}
              inputValue={email}
              setValue={setEmail}
            />
            <InputField
              validateInput={validateInput}
              fieldName={"Tlf. nr"}
              inputValue={phonenumber}
              setValue={setPhonenumber}
            />
            <InputField
              validateInput={validateInput}
              fieldName={"Addresse 1"}
              inputValue={address1}
              setValue={setAddress1}
            />
            <InputField
              validateInput={validateInput}
              fieldName={"Addresse 2"}
              inputValue={address2}
              setValue={setAddress2}
            />
            <InputField
              validateInput={validateInput}
              fieldName={"Poststed"}
              inputValue={poststed}
              setValue={setPoststed}
            />
            <InputField
              validateInput={validateInput}
              fieldName={"Postnummer"}
              inputValue={postNr}
              setValue={setPostNr}
            />
          </div>

          <ButtonBlack
            onClick={() => {
              const generatedErrText = generateErrText();

              if (generatedErrText.length === 0) {
                axios(`${backend}/user/info`, {
                  method: "POST",
                  withCredentials: true,
                  data: {
                    Name: name,
                    address1: address1,
                    address2: address2,
                    postNr: parseInt(postNr),
                    postSted: poststed,
                    email: email,
                    phoneNumber: phonenumber,
                  },
                })
                  .then(() => {
                    alert("User info updated!");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          >
            Oppdater profil
          </ButtonBlack>
        </div>
      )}
    </div>
  );
}

export default Profile;
