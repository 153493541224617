import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from "react";
import axios from "axios";
import { backend } from "../environment";

type Auth = {
  loggedIn: boolean;
};

const authContext = createContext<Auth>({ loggedIn: false });

export function ProvideAuth({ children }: { children: ReactNode }) {
  const { auth } = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [auth, setAuth] = useState({ loggedIn: false });
  useEffect(() => {
    axios(`${backend}/user/info`, {
      method: "GET",
      withCredentials: true,
    }).then((response) => {
      if (response.status === 200) {
        setAuth({ loggedIn: true });
      }
    });
  }, []);
  return { auth };
}
