import React from "react";

function Coathanger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="28"
      fill="none"
      viewBox="0 0 40 28"
    >
      <path
        stroke="#212121"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M25.067 5.952C25.067 3.23 22.787 1 20 1c-2.787 0-5.067 2.229-5.067 4.952 0 2.29 1.647 4.272 3.8 4.767v2.662L1 23.286V27h38v-3.714l-13.11-6.872"
      ></path>
    </svg>
  );
}

export default Coathanger;
