import React, { FC } from "react";

const InvalidIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#ED4B9E"
        fillRule="evenodd"
        d="M20.5 12a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm1.5 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-9.25-6v8h-1.5V6h1.5zM13 17a1 1 0 11-2 0 1 1 0 012 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default InvalidIcon;
