/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Coathanger from "../assets/svg/Coathanger";
import BurgerMenu from "../assets/svg/BurgerMenu";
import Close from "../assets/svg/Close";
import { backend } from "../environment";
import { useAuth } from "../utils/useAuth";

import { Link } from "react-router-dom";

const Navbar = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const auth = useAuth();
  return (
    <nav
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 24px 5vw;
        border-bottom: 1px solid #000000;
      `}
    >
      <Link to="/">
        <Coathanger />
      </Link>
      <div
        onClick={() => {
          setSideMenuOpen(!sideMenuOpen);
          console.log("asdf");
        }}
      >
        <BurgerMenu />
      </div>
      {auth.loggedIn && sideMenuOpen ? (
        <div
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            width: 60vw;
            z-index: 5;
            padding: 24px 5vw;
            background-color: #edc6ec;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <MenuItem
              to="/profile"
              onClick={() => {
                setSideMenuOpen(false);
              }}
            >
              Min Konto
            </MenuItem>
            <MenuItem
              to="/profile"
              onClick={() => {
                setSideMenuOpen(false);
              }}
            >
              Betalinger
            </MenuItem>
            <MenuItem
              to="/profile"
              onClick={() => {
                setSideMenuOpen(false);
              }}
            >
              Solgte Klær
            </MenuItem>
            <MenuLink href={`${backend}/user/logout`}>Logg Ut</MenuLink>
          </div>
          <div
            onClick={() => {
              setSideMenuOpen(!sideMenuOpen);
            }}
          >
            <Close />
          </div>
        </div>
      ) : (
        <>
          {sideMenuOpen && (
            <div
              css={css`
                position: fixed;
                top: 0;
                right: 0;
                height: 100vh;
                width: 60vw;
                z-index: 5;
                padding: 24px 5vw;
                background-color: #edc6ec;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <MenuLink
                  href={`${backend}/user/login`}
                  onClick={() => {
                    setSideMenuOpen(false);
                  }}
                >
                  Logg Inn
                </MenuLink>
                <MenuLink
                  href={`${backend}/user/login`}
                  onClick={() => {
                    setSideMenuOpen(false);
                  }}
                >
                  Lag Bruker
                </MenuLink>
              </div>
              <div
                onClick={() => {
                  setSideMenuOpen(!sideMenuOpen);
                }}
              >
                <Close />
              </div>
            </div>
          )}
        </>
      )}
    </nav>
  );
};

export default Navbar;

const MenuItem = styled(Link)`
  font-size: 2.4rem;
  color: black;
  text-decoration: none;
`;

const MenuLink = styled.a`
  font-size: 2.4rem;
  color: black;
  text-decoration: none;
`;
