import React, { FC } from "react";

const CheckmarkValidated: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#31D0AA"
        strokeWidth="1.5"
        d="M4 12.5l3.586 3.586a2 2 0 002.828 0L20.5 6"
      ></path>
    </svg>
  );
};

export default CheckmarkValidated;
