import React from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { backend } from "./environment";
import pose from "./assets/pose.jpg";
import { useAuth } from "../src/utils/useAuth";

function App() {
  const auth = useAuth();

  return (
    <Background>
      {auth.loggedIn ? (
        <>
          <div
            onClick={() => {
              axios(`${backend}/user/bagorder`, {
                method: "POST",
                withCredentials: true,
              })
                .then(() => {
                  alert("Nå kommer posen!");
                })
                .catch((err) => {
                  console.log(err);
                  if (err.response.status === 400) {
                    alert(
                      "User has not set an address, go to profile and fill address information"
                    );
                  }
                });
            }}
          >
            <Card color="#FFD481" title="Bestille hentepose?" image={pose} />
          </div>
          <Card color="#FFF281" title="Har du en pose klar til sending?" />
        </>
      ) : (
        <a href={`${backend}/user/login`}>
          <Card
            color="#FFD481"
            title="Logg deg inn, eller lag en bruker. Det er gøy!"
            image={pose}
          />
        </a>
      )}
    </Background>
  );
}

export default App;

type CardProp = {
  title: string;
  color: string;
  image?: string;
  alt?: string;
};

function Card(props: CardProp) {
  return (
    <CardContainer color={props.color}>
      <h2>{props.title}</h2>
      {props.image && <CardImage src={props.image} alt={props.alt} />}
    </CardContainer>
  );
}

const CardImage = styled.img``;

const CardContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  min-height: 200px;
  max-width: 400px;
  width: 80vw;
  margin-bottom: 2.4rem;
  padding: 2vw;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
