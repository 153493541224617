import React, { FC, ReactNode } from "react";
import styled from "@emotion/styled";

const ButtonBlack: FC<{ children: ReactNode; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return <ButtonStyle onClick={onClick}>{children}</ButtonStyle>;
};

export default ButtonBlack;

const ButtonStyle = styled.button`
  color: white;
  width: 150px;
  height: 50px;
  background: #000000;
  box-shadow: 6px 8px 2px -5px rgba(14, 14, 44, 0.1);
  border-radius: 6px 24px 6px 6px;
  border: none;
`;
