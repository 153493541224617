/** @jsxImportSource @emotion/react */

import React, { FC, useState } from "react";
import { css } from "@emotion/react";
import CheckmarkValidated from "../assets/svg/CheckmarkValidated";
import InvalidIcon from "../assets/svg/InvalidIcon";
import styled from "@emotion/styled";

const InputField: FC<{
  fieldName: string;
  inputValue: string;
  setValue: (input: string) => void;
  validateInput: (input: string) => boolean;
}> = ({ fieldName, inputValue, setValue, validateInput }) => {
  const [inputValidated, setInputValidated] = useState<undefined | boolean>(
    undefined
  );

  return (
    <InputSection>
      {fieldName}:
      <InputDiv
        css={css`
          position: relative;
        `}
      >
        <StyledInput
          onFocus={() => {
            setInputValidated(validateInput(inputValue));
          }}
          onChange={(e) => {
            setValue(e.target.value);
            setInputValidated(validateInput(e.target.value));
          }}
          type="text"
          name={fieldName}
          value={inputValue}
          isValidated={inputValidated}
        />

        <ValidStyledIcon isValidated={inputValidated} />
        <InvalidStyledIcon isValidated={inputValidated} />
      </InputDiv>
    </InputSection>
  );
};

export default InputField;

const StyledInput = styled.input<{ isValidated?: boolean }>`
  background: #ecf1f4;

  box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
  border-radius: 6px;
  border: none;
  height: 24px;
  margin-left: 5px;
  padding: 5px;

  &:focus {
    background: #ffffff;

    outline: none;
    border: 1px solid #4b4ded;
    border: ${(props) =>
      props.isValidated && props.isValidated !== undefined
        ? `1px solid #31D0AA`
        : `1px solid #ED4B9E`};
    box-shadow: 0px 0px 0px 4px rgba(75, 77, 237, 0.2);
    box-shadow: ${(props) =>
      props.isValidated && props.isValidated !== undefined
        ? `0px 0px 0px 4px rgba(49, 208, 170, 0.2)`
        : `0px 0px 0px 4px rgba(237, 75, 158, 0.2)`};
  }
`;

const InputDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputSection = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const InvalidStyledIcon = styled(InvalidIcon)<{
  isValidated?: boolean;
}>`
  ${(props) =>
    (props.isValidated || props.isValidated === undefined) && `display: none;`}
  position: absolute;
  right: 4%;
`;

const ValidStyledIcon = styled(CheckmarkValidated)<{
  isValidated?: boolean;
}>`
  ${(props) => !props.isValidated && `display: none;`}
  position: absolute;
  right: 4%;
`;
